<template>
    <div role="main" class="main">
      <section
        class="common-page with-footer together_we_join image-page enlarge-page"
      >
      <div class="loaderWrapper" v-if="showLoader">
          <double-bounce ></double-bounce>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-9">
              <div class="border-l text-left">
                <p class="text-uppercase">
                  Together we join you. We are here with you
                </p>
              </div>
            </div>
            <div class="col-12"  style="padding-top:30px">
              <p>
               {{message}}
              </p>
              <ul class="list-unstyled">
                <li v-for="visitor in visitors" :key="visitor.id">
                  <a href="javascript:void(0);">
                    <img
                      :src="visitor.profile_url"
                      class="img-fluid br-50 fixed-image"
                    />
                  </a>
                  <br />
                  <small> {{ visitor.full_name }}</small>
                </li>
              </ul>
            </div>
          </div>
          <!-- <transition name="pop" appear>
            <div
              class="modal fade show custom-modal"
              v-if="show_count_modal"
              id="Countdown"
              tabindex="-1"
              role="dialog"
              aria-labelledby="Countdown"
              aria-hidden="true"
              style="z-index: 9000"
            >
              <div
                class="modal-dialog modal-dialog-centered"
                style="width: 300px !important"
                role="document"
              >
                <div class="modal-content">
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-lg-12 text-center">
                        <h5 class="text-custom my-4">
                          Stand by<br />for our<br />next speaker.
                        </h5>
                        <div class="countdown">
                          {{ count_number }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition> -->
        </div>
      </section>
    </div>
  </template>
  <script>
  import { getApiManager } from "../../api";
  import { apiBaseUrl, liveBaseUrl } from "../../constants/config";
  import {DoubleBounce} from 'vue-loading-spinner'
  import adapter from "webrtc-adapter";
  window["adapter"] = adapter;
  
  export default {
    data() {
      return {
        visitors: [],
        event_id: 0,
        connection: null,
        event: null,
        count_number: 10,
        show_count_modal: false,
        message: "",
        showLoader : false,
      };
    },
    mounted() {
      this.event_id = JSON.parse(localStorage.getItem("loginInfo"))
        ? JSON.parse(localStorage.getItem("loginInfo")).event_id
        :  window.location.href.split('/')[window.location.href.split('/').length-1];
      let ref = this;
      
      this.getVisitorList(this.event_id)
      setInterval(() => {
        this.getVisitorList(this.event_id)
        
      }, 60000);
      
    },
    methods: {
    
   
      getVisitorList(event_id) {
        let ref = this;
        this.showLoader = true;
        this.message = 'List of visitors'
        getApiManager()
          .post(`${apiBaseUrl}/api/visitor/event/getEventDetail`, {
            event_id: event_id,
          })
        .then((response) => {
          let res = response.data;
          console.log("[[[[",res)
          this.showLoader = false;
          if(res.data[0].status != 3){

          getApiManager()
            .post(`${apiBaseUrl}/api/visitor/all`, { event_id: event_id })
            .then((response) => {
                console.log('response: ', response);
            let res = response.data;
            if (res.status === true) {
                let detail = res.detail;
                ref.visitors = detail.visitors;
                for (let i = 0; i < ref.visitors.length; i++) {
                ref.visitors[i].profile_url =
                    apiBaseUrl + "/" + ref.visitors[i].profile_url;
                }
                ref.event = detail.event;
            }
            })
            .catch((error) => {});
        }else{
            this.message = 'This event is closed.'
        }
    })

      },
    },
    components: {
      DoubleBounce
    },
    computed: {},
    watch: {},
  };
  </script>
  <style scoped>
  .loaderWrapper{
    position: absolute;
      height: 89vh;
      width: 100vw;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #00000082;
      z-index: 999;
  }
  </style>